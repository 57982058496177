import { Button, Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import styled from 'styled-components';
import { media } from '@quiqupltd/QuReactSDK/lib/styles/mixins/media';

interface AccountTypeProps {
  selected: boolean;
}

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0px 0px 0px;
`;

export const FormContainer = styled.form`
  z-index: 99;
  margin-bottom: 60px;
  max-height: 90%;
  max-width: 500px;
  overflow: auto;
  border-radius: 8px;
  background-color: white;
  padding: 24px 32px 24px 32px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  gap: 12px;

  .title {
    color: ${(p): string => p.theme.midnight500};
    font-size: 18px;
  }

  .progress-bar {
    margin-top: 20px;
  }

  .sub-title {
    color: ${(p): string => p.theme.slate500};
  }

  .form-label {
    color: ${(p): string => p.theme.slate700};
    font-weight: 500;
    padding-bottom: 4px;
  }

  .input {
    border-radius: 4px;
    height: 39px;
    width: 100%;
    color: ${(p): string => p.theme.slate700};
  }

  .reviews-title {
    color: ${(p): string => p.theme.slate500};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .forgot-pass-label {
    font-size: 11px;
    color: ${(p): string => p.theme.cornFlower500};
  }

  .footer-message {
    padding-top: 12px;
    font-size: 12px;
    color: ${(p): string => p.theme.grey};

    .link {
      color: ${(p): string => p.theme.cornFlower500};
      margin-left: 2px;
    }

    .link-text {
      margin-left: 2px;
    }
  }
`;

export const FormSubmit = styled(Button)`
  background-color: ${(p): string => p.theme.sunflower500};
  color: ${(p): string => p.theme.midnight600};
  border-color: ${(p): string => p.theme.sunflower500};
  border-radius: 4px;
  width: 100%;
`;

export const DoubleContainer = styled.div<{ shouldShow: boolean }>`
  display: ${(p): string => (p.shouldShow ? 'grid' : 'none')};
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 90px 50px 0px 50px;

  ${media.down('tablet')`
    grid-template-columns: 1fr;
    margin: 10px 0 ;  
  `};
`;

export const InfoContainer = styled.div`
  ${media.down('tablet')`
   display: none;
`};

  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(p): string => p.theme.midnight500};
    margin-bottom: 8px;
  }

  .sub-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(p): string => p.theme.midnight500};
  }

  .text {
    margin-left: 34px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(p): string => p.theme.slate500};
  }
`;

export const AccountTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 8px;
`;

export const AccountTypeBox = styled.div<AccountTypeProps>`
  cursor: pointer;
  flex: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  border: 1px solid;
  padding: 15px 50px 15px 50px;
  border-color: ${(p): string => (p.selected ? p.theme.slate700 : p.theme.slate200)};
  color: ${(p): string => (p.selected ? p.theme.slate700 : p.theme.slate200)};

  .account-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const ReviewBox = styled.div`
  text-align: center;
  background-color: ${(p): string => p.theme.ghost200};
  padding: 8px 4px 8px 4px;
  border-radius: 4px;
  .review-message {
    color: ${(p): string => p.theme.midnight500};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .review-date {
    color: #9b8bb4;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const StyledInput = styled<InputProps & { hasError?: boolean; hide?: boolean }>(Input)`
  ${(p): string =>
    p.hide
      ? `
  display: none !important;
`
      : ''}
  ${(p): string =>
    p.hasError
      ? `
  border-color: ${p.theme.red} !important;
`
      : ''}
`;

export const Hint = styled.div<{ color?: 'red' | 'green' }>`
  font-size: 1.2rem;
  color: ${(p): string => (p.color === 'green' ? '#49CF56' : p.theme.red)};
`;
